<script setup>
import { ref } from 'vue';
import MyToast from './MyToast.vue';

const show = ref(false)

// function showToast() {
//   show.value = true
//   setTimeout(()=> {
//     show.value = false
//   }, 2000)
// }
</script>
<template>
  <div class="download_block">
    <a href="https://apps.apple.com/gb/app/pppoker-go/id6472884170" target="_blank">
      <img class="download_btn" src="/img/download/App Store.png" alt="apple" />
    </a>
    <a href="https://play.google.com/store/apps/details?id=com.ppgo.dis" target="_blank">
      <img class="download_btn" src="/img/download/Google Play.png" alt="android" />
    </a>

    <MyToast v-model="show"></MyToast>
  </div>
</template>

<style lang="scss" scoped>
.download_block {
  overflow: hidden;
  white-space: nowrap;
  padding: 10px;
  
  @media screen and (max-width: 750px) {
    width: 100%;
    a {
      margin: 0 5px;
      display: inline-block;
      width: 45%;
    }
  }
}

.download_btn {
  max-width: 169px;
  width: 100%;
  margin: 0 10px;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

  @media screen and (max-width: 750px) {
    // width: 22.5vw;
  }

  &:hover {
    transform: scale(1.1);
  }
}
</style>
