<script setup>
function anchorScroll(id) {
  const element = document.querySelector(id);
  const topPos = element.getBoundingClientRect().top + window.pageYOffset;
  const headerHeight = 120
  window.scrollTo({
    top: topPos - headerHeight, // scroll so that the element is at the top of the view
    behavior: "smooth", // smooth scroll
  });
}
</script>
<template>
  <header>
    <div class="container">
      <img class="logo" width="117px" alt="logo" src="/img/LOGO.png" />

      <div class="icon" tabindex="0">
        <a
          target="_blank"
          href="https://www.facebook.com/pppokergo"
          aria-label=""
          class="facebook-img"
        ></a
        ><a
          target="_blank"
          href="https://www.instagram.com/pppokergo"
          aria-label=""
          class="instagram-img"
        ></a>
        <a class="contact" @click="anchorScroll('#contact_us')"></a>
      </div>
    </div>
  </header>
</template>
<style lang="scss" scoped>
header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  width: 100%;
  height: 90px;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: row;
  align-items: center;
}
.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.icon {
  margin-left: auto;
  a {
    margin: 0 10px;
    display: inline-block;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    width: 26px;
    height: 26px;
  }
}
.icon .facebook-img {
  background-image: url("../../public/img/Facebook.png");
  &:hover {
    background-image: url("../../public/img/Facebook_yellow.png");
  }
}

.icon .instagram-img {
  background-image: url("../../public/img/Ins.png");
  &:hover {
    background-image: url("../../public/img/Ins_yellow.png");
  }
}

.icon .contact {
  margin-left: 14px;
  background-image: url("../../public/img/Contact_Us.png");
  &:hover {
    background-image: url("../../public/img/Contact_Us_yellow.png");
  }
}

// .menu_item {
//   text-decoration: none;
//   padding: 0 15px;
//   height: 60px;
//   line-height: 60px;
//   font-family: MicrosoftYaHei;
//   font-size: 18px;
//   font-stretch: normal;
//   font-style: normal;
//   letter-spacing: normal;
//   text-align: left;
//   color: #fff;

//   &:hover {
//     color: #ffda80;
//   }
// }
</style>
