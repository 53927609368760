<script setup>
import { defineModel } from "vue";
const model = defineModel();
</script>
<template>
  <transition name="fade">
    <div v-show="model">Coming soon</div>
  </transition>
</template>
<style scoped>
div {
  position: fixed;
  top: 30%;
  left: 50%;
  padding: 20px 30px;
  background-color: #424242;
  border-radius: 5px;
  text-align: center;
  transform: translate(-50%, -50%);
  z-index: 9999;
  font-family: MicrosoftYaHei;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
/* vue动画过渡效果设置 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
